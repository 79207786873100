<template>
    <div>
        <div class="demo-spin-article">
            <div class="row mb-3">
                <div class="col-md-4">
                    <label class="form-col-label control-label required">{{
                        $t("loanTransfer.transferDate")
                    }}</label>
                    <DatePicker
                        :value="model.transfer_date"
                        placeholder="DD-MM-YYYY"
                        format="dd-MM-yyyy"
                        @on-change="onChangeTransferDate"
                        :options="options"
                        style="width: 100%"
                    ></DatePicker>
                    <div class="text-danger" v-if="errors.has('transfer_date')">
                        {{ errors.first("transfer_date") }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="form-col-label control-label required">{{
                        $t("loanTransfer.fromCo")
                    }}</label>
                    <Select
                        v-model="model.from_co_id"
                        filterable
                        clearable
                        :placeholder="$t('loanTransfer.fromCo')"
                        :class="{
                            'ivu-form-item-error': errors.has('from_co_id')
                        }"
                        @on-change="getLoan"
                        @on-select="() => (model.details = [])"
                        @on-clear="() => (model.details = [])"
                    >
                        <Option
                            v-for="(option, index) in fromEmployees"
                            :value="option.employee_id"
                            :key="index"
                        >
                            {{ option.employee_name_en }} |
                            {{ option.employee_name_kh }}
                        </Option>
                    </Select>
                </div>
                <div class="col-md-4">
                    <label class="form-col-label control-label required">{{
                        $t("loanTransfer.toCo")
                    }}</label>
                    <Select
                        v-model="model.to_co_id"
                        filterable
                        clearable
                        :placeholder="$t('loanTransfer.toCo')"
                        :class="{
                            'ivu-form-item-error': errors.has('to_co_id')
                        }"
                    >
                        <Option
                            v-for="(option, index) in toEmployees"
                            :value="option.employee_id"
                            :key="index"
                        >
                            {{ option.employee_name_en }} |
                            {{ option.employee_name_kh }}
                        </Option>
                    </Select>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t("loanTransfer.reason")
                    }}</label>
                    <textarea
                        v-model="model.remarks"
                        class="form-control"
                        rows="3"
                        :class="{
                            'is-invalid': errors.has('remarks')
                        }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first("remarks") }}
                    </div>
                </div>
            </div>
            <div class="tw-mb-6">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("loanTransfer.loanDisbursement") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Double click on table row or click icon
                                </div>
                                <span class="tw-text-xs"
                                    >plus to add item in to item details.</span
                                >
                            </div>
                        </Tooltip>
                    </legend>
                    <div class="col-md-12">
                        <div class="tw-flex tw-justify-end tw-mb-3 tw-mt-2">
                            <Input
                                v-model="model.search"
                                search
                                :placeholder="$t('loanTransfer.searchLoan')"
                                style="width: 309px"
                            />
                            <ts-button
                                color="primary"
                                outline
                                :disabled="loanDisbursements.length <= 0 || loanLoading"
                                :waiting="loanLoading"
                                @click.prevent="addAll"
                                class="tw-ml-2"
                            >
                                {{ $t("addAll") }}</ts-button
                            >
                        </div>
                        <Table
                            height="150"
                            border
                            :columns="columns"
                            :data="loanDisbursements"
                            size="small"
                            :loading="loanLoading"
                            @on-row-dblclick="onAddItem"
                            :row-class-name="rowClassName"
                            stripe
                        >
                            <template slot-scope="{ row }" slot="customerName">
                                {{
                                    row.customer
                                        ? row.customer.customer_name_en
                                        : ""
                                }}
                            </template>
                            <template slot-scope="{ row }" slot="is_write_off">
                                <span
                                    v-if="row.is_write_off"
                                    class="badge bg-success tw-mr-1 tw-capitalize"
                                >
                                    Yes
                                </span>
                                <span
                                    v-else
                                    class="badge bg-secondary tw-mr-1 tw-capitalize"
                                >
                                    No
                                </span>
                            </template>
                            <template slot-scope="{ row }" slot="loan_amount">
                                <strong>{{
                                    formatCurrencyWithCode(
                                        row.loan_amount,
                                        row.currency
                                    )
                                }}</strong>
                            </template>
                            <template slot-scope="{ row }" slot="action">
                                <Tooltip
                                    placement="top"
                                    :transfer="true"
                                    :content="$t('add')"
                                >
                                    <a
                                        href="#"
                                        class="text-blue"
                                        @click.prevent="onAddItem(row)"
                                    >
                                        <Icon
                                            type="ios-add-circle-outline"
                                            size="20"
                                        />
                                    </a>
                                </Tooltip>
                            </template>
                        </Table>
                    </div>
                </fieldset>
            </div>
            <div class="mt-3 mb-3">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("loanTransfer.itemDetail") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Journal entry item details.
                                </div>
                            </div>
                        </Tooltip>
                    </legend>
                    <div
                        class="tw-mt-2 overflow-auto tw-max-h-40"
                        style="min-height: 100px;"
                    >
                        <div class="table-ui-detail">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {{ $t("loanTransfer.loanNumber") }}
                                        </th>
                                        <th width="25%">
                                            {{
                                                $t("loanTransfer.customerName")
                                            }}
                                        </th>
                                        <th class="tw-text-right" width="10%">
                                            {{ $t("loanTransfer.loanAmount") }}
                                        </th>
                                        <th class="tw-text-right" width="10%">
                                            {{
                                                $t(
                                                    "loanTransfer.outstandingAmount"
                                                )
                                            }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{ $t("actions") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(detail, index) in model.details"
                                        :key="index"
                                    >
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.loan_number }}
                                        </td>
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.customer_name }}
                                        </td>
                                        <td class="tw-text-right">
                                            <strong>{{
                                                formatCurrencyWithCode(
                                                    detail.loan_amount,
                                                    detail.currency
                                                )
                                            }}</strong>
                                        </td>
                                        <td class="tw-text-right">
                                            <strong>{{
                                                formatCurrencyWithCode(
                                                    detail.outstanding_amount,
                                                    detail.currency
                                                )
                                            }}</strong>
                                        </td>
                                        <td class="text-center">
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                :content="$t('remove')"
                                            >
                                                <a
                                                    href="#"
                                                    class="text-danger"
                                                    @click.prevent="
                                                        removeRow(detail)
                                                    "
                                                >
                                                    <Icon
                                                        type="ios-remove-circle-outline"
                                                        size="20"
                                                    />
                                                </a>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
                <div class="validate text-danger" v-if="errors.has('details')">
                    {{ errors.first("details") }}
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty, sortBy, debounce, includes } from "lodash";
import moment from "moment";

export default {
    name: "loanTransferForm",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            loanLoading: false,
            model: {
                search: null,
                transfer_date: moment().format("DD-MM-YYYY"),
                from_co_id: null,
                to_co_id: null,
                remarks: null,
                details: []
            },
            options: {
                disabledDate(date) {
                    return date.valueOf() > Date.now();
                }
            }
        };
    },
    computed: {
        ...mapState("creditOperation/loanTransfer", ["edit_data"]),
        ...mapGetters(["formatCurrencyWithCode", "formatNumber"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        fromEmployees() {
            return sortBy(
                this.$store.state.creditOperation.loanTransfer.employees.filter(
                    e => e.employee_id != this.model.to_co_id
                ),
                ["employee_name_en"]
            );
        },
        toEmployees() {
            return sortBy(
                this.$store.state.creditOperation.loanTransfer.employees.filter(
                    e => e.employee_id != this.model.from_co_id
                ),
                ["employee_name_en"]
            );
        },
        loanDisbursements() {
            if (this.model.details.length > 0) {
                return this.$store.state.creditOperation.loanTransfer.loanDisbursements.filter(
                    l =>
                        !includes(
                            this.model.details.map(d => d.loan_id),
                            l.loan_id
                        )
                );
            }
            return this.$store.state.creditOperation.loanTransfer
                .loanDisbursements;
        },
        columns() {
            return [
                {
                    title: this.$t("loanTransfer.loanNumber"),
                    key: "loan_number",
                    fixed: "left",
                    width: 150
                },
                {
                    title: this.$t("loanTransfer.customerName"),
                    slot: "customerName"
                },
                {
                    title: this.$t("loanTransfer.loanAmount"),
                    slot: "loan_amount",
                    align: "center",
                    width: 120
                },
                {
                    title: this.$t("loanTransfer.isWriteOff"),
                    slot: "is_write_off",
                    align: "center",
                    width: 120
                },
                {
                    title: this.$t("actions"),
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 85
                }
            ];
        }
    },
    methods: {
        ...mapActions("creditOperation/loanTransfer", ["getEmployees"]),
        onChangeTransferDate(date) {
            this.model.transfer_date = date;
        },
        async fetchResource() {
            this.loading = true;
            await this.getEmployees();
            this.setEditData();
            this.loading = false;
        },
        getLoan() {
            if (!this.model.from_co_id) {
                this.$store.commit(
                    "creditOperation/loanTransfer/SET_LAON_DISBURSEMENTS",
                    []
                );
                return;
            }
            this.loanLoading = true;
            this.$store
                .dispatch("creditOperation/loanTransfer/getLoanDisbursement", {
                    search: this.model.search,
                    co_id: this.model.from_co_id
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loanLoading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/loanTransfer/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanTransfer/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanTransfer/update", {
                    id: this.edit_data.transfer_co_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            this.errors = new Errors();
            if (!isEmpty(this.edit_data)) {
                this.loading = true;
                await this.getEmployees();
                this.model.from_co_id = this.edit_data.from_co_id;
                await this.getLoan();
                this.model.transfer_date = this.edit_data.transfer_date;
                this.model.from_co_id = this.edit_data.from_co_id;
                this.model.to_co_id = this.edit_data.to_co_id;
                this.model.remarks = this.edit_data.remarks;
                this.edit_data.details.forEach(detail => {
                    this.model.details.unshift({
                        loan_id: detail.loan_id,
                        loan_number: detail.loan.loan_number,
                        customer_name: detail.loan.customer.customer_name_en,
                        loan_amount: detail.loan.loan_amount,
                        outstanding_amount: detail.outstanding_amount,
                        currency: detail.loan.currency
                    });
                });
                this.loading = false;
            }
        },
        clearInput() {
            this.$store.commit(
                "creditOperation/loanTransfer/SET_LAON_DISBURSEMENTS",
                []
            );
            this.model.search = null;
            this.model.transfer_date = moment().format("DD-MM-YYYY");
            this.model.from_co_id = null;
            this.model.to_co_id = null;
            this.model.remarks = null;
            this.model.details = [];
        },
        rowClassName() {
            return "cursor-pointer";
        },
        cellClassName() {
            return "demo-table-info-column";
        },
        onAddItem(row) {
            this.model.details.unshift({
                loan_id: row.loan_id,
                loan_number: row.loan_number,
                customer_name: row.customer ? row.customer.customer_name_en : '',
                loan_amount: row.loan_amount,
                outstanding_amount: row.outstanding_amount,
                currency: row.currency
            });
        },
        removeRow(item) {
            this.model.details.splice(this.model.details.indexOf(item), 1);
        },
        addAll() {
            this.loanLoading = true;
            this.$store
                .dispatch("creditOperation/loanTransfer/getLoanDisbursement", {
                    search: this.model.search,
                    co_id: this.model.from_co_id,
                    perPage: -1
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loanLoading = false;
                    this.loanDisbursements.forEach(row => {
                        this.model.details.unshift({
                            loan_id: row.loan_id,
                            loan_number: row.loan_number,
                            customer_name: row.customer ? row.customer.customer_name_en : '',
                            loan_amount: row.loan_amount,
                            outstanding_amount: row.outstanding_amount,
                            currency: row.currency
                        });
                    });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LAON TRANSFER",
                desc: not.text
            });
        }
    },
    watch: {
        "model.search": debounce(function() {
            this.getLoan();
        }, 500)
    }
};
</script>
